import React, { Fragment } from "react"
import EstateStatus from "../estateStatus"
import EstateTitle from "../estateTitle"
import EstateNav from "./estateNav"
import { HeaderContainer } from "../janowotwo/janowoTwoStyle"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "gatsby-plugin-intl"
import logo from "@/images/z-20/logo_puck.svg"

const PuckHeader = ({ scrollDestination, intl }) => {
  const noPadding = { margin: "auto" }

  return (
    <Fragment>
      <HeaderContainer>
        <Container style={noPadding}>
          <Row>
            <Col
              className="content-wrapper"
              style={noPadding}
              xs="12"
              sm="12"
              md="6"
              lg="4"
            >
              <EstateTitle
                subTitleBold="Inwestycja znajduje się w cichej, doskonale skomunikowanej dzielnicy Pucka, jedynie 1500m od plaży i zaledwie 20 minut jazdy samochodem od Trójmiasta."
                subtitle="Osiedle Pogodne to komfort współgrający z otaczającą przyrodą. <strong>Kameralna zabudowa</strong> zamkniętego Osiedla, doskonale wkomponowana w strukturę nadmorskiego Pucka, to <strong>gwarancja spokoju</strong> i&nbsp;wewnętrznego balansu pomiędzy nowoczesnym stylem życia a kojącą bliskością natury."
              />
            </Col>
            <Col
              xs="12"
              sm="12"
              md="6"
              lg="4"
              className={"justify-content-center align-items-center d-flex"}
            >
              <img
                src={logo}
                alt={"Osiedle Pogodne"}
                style={{ maxWidth: "100%", height: "224px" }}
              />
            </Col>
            <Col className="header-nav" xs="0" sm="0" md="6" lg="4">
              <EstateNav scrollDestination={scrollDestination} />
            </Col>
            <Col className="desktop-info" xs="12">
              <div>
                43 <span>mieszkania</span>
              </div>

              <div>
                1 <span>lokal usługowy</span>
              </div>
              <div>
                30 - 75m<sup>2</sup>
                <span>
                  {" "}
                  {intl.formatMessage({
                    id: "offer.janowo.info.space.name",
                  })}
                </span>
              </div>
              <div>
                <span>Planowane odbiory:</span> IV kwartał 2025
              </div>
            </Col>
          </Row>
        </Container>
      </HeaderContainer>
    </Fragment>
  )
}

export default injectIntl(PuckHeader)
